import { faTachometerAlt, faTasks, faPowerOff } from '@fortawesome/free-solid-svg-icons';

import { RouteInfo } from './core/model/routeInfo.model';

export const MainPath: string = '/home';
export const LoginPath: string = '/login';
export const LogoutPath: string = '/logout';
export const GdprConfirmPath: string = '/user/gdpr';
export const UnAuthorize: string = '/unAuthoize';

/*this array has to include every major route that the webportal is offering.*/

export const ROUTES: RouteInfo[] = [
  {
    path: MainPath, title: 'Home', secure: true, icon: faTachometerAlt, class: '', sortValue: 1, hidden: false,
  }
  /*,{
     path: LogoutPath, title: 'Logout', secure: false, icon: faPowerOff, class: '', sortValue: 99, hidden: false,
   }*/
];

