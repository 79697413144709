import { AboutModel } from './about.model';
import { TextModel } from './text.model';

import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

export class TenantModel {
  Version: number = 0;
  StyleKey: string = "DEMO";

  DefaultLanguage: string = "en";
  SupportedLanguages: Array<string> = new Array<string>('en');

  Licenses: Array<number> = new Array<number>();

  DefaultLatitude: number = 0;
  DefaultLongitude: number = 0;

  LoginLogoFileId: string = "";
  LoginType: string = "";
  LoginRecoverPasswordType: string = "";
  LoginNumbersOnly: boolean = false;

  DateFormat: string = "yyyy-mm-dd";
  DateTimeFormat: string = "yyyy-mm-dd hh:mm";

  ApplicationTitle: string = "";
  //DisplayApplicationTitle: string = "";

  HarbourName: string = "";
  //DisplayHarbourName: string = "";

  AppBaseUrl: string = "";
  AdminUrl: string = "";
  AdminRoles: Array<number> = new Array<number>();

  Applications: Array<SubAppModel> = new Array<SubAppModel>();
  HomeLinksAnonymus: Array<SubAppModel> = new Array<SubAppModel>();
  HomeLinksLoggedin: Array<SubAppModel> = new Array<SubAppModel>();

  About: AboutModel = new AboutModel();
  Contact: AboutModel = new AboutModel();

  Texts: Array<TextModel> = new Array<TextModel>();

  public constructor(init?: Partial<TenantModel>) {
    Object.assign(this, init);
  }
}

export class SubAppModel {
  Promo: boolean = false;
  Icon: string = "";
  Color: string = "";
  Text: string = "";
  DisplayText: string = "";
  RouteMode: string = "";
  NavRoute: string = "";
  Route: string = "";
  Roles: Array<number> = new Array<number>();
  Licenses: Array<number> = new Array<number>();
  //CssClass: string = "";
  faIcon: IconDefinition;
  faIconColor: string = "";
  isHidden: boolean;

  public constructor(init?: Partial<SubAppModel>) {
    Object.assign(this, init);
  }
}
