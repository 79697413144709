import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable, throwError, timer } from 'rxjs';
import { timeout, retry, retryWhen, delayWhen, catchError, distinctUntilChanged } from 'rxjs/operators';

import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalStorageService } from 'ngx-localstorage';

import { ConfigurationModel } from '../model/configuration.model';
import { TenantModel } from '../model/tenant.model';
import { TokenModel } from '../model/token.model';
import { AuthenticationBasicResponseModel, SecurityTokenModel } from '../model/authentication.model';

import { LocalStorageNames } from '../core.statics';
import { CookieService } from 'ngx-cookie-service';

import { RetryStrategy } from 'src/app/_handlers/retry-strategy';
import { ConfigurationValueModel } from '../model/configurationvalue.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private configurationUrl: string = '/assets/config.json';

  private _bsAllConfigsLoaded = new BehaviorSubject<boolean>(false);
  private _bsConfiguration = new BehaviorSubject<ConfigurationModel>(new ConfigurationModel);
  private _bsTenant = new BehaviorSubject<TenantModel>(new TenantModel);
  private _bsDeviceToken = new BehaviorSubject<string>("");
  private _bsSecurityToken = new BehaviorSubject<SecurityTokenModel>(new SecurityTokenModel);

  private _deviceInfo: any = null;

  readonly allConfigsLoaded = this._bsAllConfigsLoaded.asObservable().pipe(distinctUntilChanged());
  readonly configuration = this._bsConfiguration.asObservable().pipe(distinctUntilChanged());
  readonly tenant = this._bsTenant.asObservable().pipe(distinctUntilChanged());
  readonly deviceToken = this._bsDeviceToken.asObservable().pipe(distinctUntilChanged());

  constructor(private http: HttpClient,
    private localStorageService: LocalStorageService,
    private ddService: DeviceDetectorService, private cookieService: CookieService,
  ) {
    this._deviceInfo = this.ddService.getDeviceInfo();
  }

  getApiUrl(apiFunction: string): string {
    return this._bsConfiguration.value.ApiBaseUrl +
      "/" + this._bsConfiguration.value.ConfigurationKey +
      "/" + this._bsConfiguration.value.ApiClientName +
      "/" + apiFunction;
  }

  getDockerApiUrl(apiFunction: string): string {
    return this._bsConfiguration.value.ApiBaseUrl +
      "/" + this._bsConfiguration.value.ConfigurationKey +
      "/" + apiFunction;
  }
  getWorkorderApiUrl(apiFunction: string): string {
    return this._bsConfiguration.value.ApiBaseUrl +
      "/" + this._bsConfiguration.value.ConfigurationKey +
      "/" + apiFunction;
  }
  getPipeBookingApiUrl(apiFunction: string): string {
    return this._bsConfiguration.value.ApiBaseUrl +
      "/" + this._bsConfiguration.value.ConfigurationKey +
      "/" + apiFunction;
  }
  getGateApiUrl(apiFunction: string): string {
    return "/gate-api" +
      "/" + this._bsConfiguration.value.ConfigurationKey +
      "/" + apiFunction;
  }

  getApiBinaryUrl(id: string, thumbnail?: boolean): string {
    if (this._bsConfiguration.value.UseBinaryPlaceholder != "true") {
      return this._bsTenant.value.AppBaseUrl +
        this._bsConfiguration.value.ApiBaseUrl +
        "/Binary?id=" + id +
        "&token=" + this._bsDeviceToken.value +
        "&key=" + this._bsConfiguration.value.ConfigurationKey +
        "&thumbnail=" + (thumbnail === true ? "true" : "false");
    }
    else {
      return this.getApiBinaryPlaceholderUrl(thumbnail);
    }
  }

  private getApiBinaryPlaceholderUrl(thumbnail?: boolean): string {
    if (thumbnail === true) {
      return "/assets/img/placeholder-thumbnail.jpg";
    }
    else {
      return "/assets/img/placeholder.jpg";
    }
  }

  getApiHeaders(contentType?: string): HttpHeaders {
    var h = {
      'Accept': '*/*'
    };

    switch (contentType) {
      case 'text': {
        h['Content-Type'] = 'text/plain';
      } break;
      case 'application/octet-stream': {
        h['Content-Type'] = 'application/octet-stream';
      } break;
      case 'json':
      default: {
        h['Content-Type'] = 'application/json';
      } break;
    }

    h['Device-Token'] = !!this._bsDeviceToken.value ? this._bsDeviceToken.value : this.getDeviceTokenFromLocalStorage();
    h['Security-Token'] = !!this._bsSecurityToken.value && !!this._bsSecurityToken.value.SecurityToken ? this._bsSecurityToken.value.SecurityToken : this.getSecurityTokenFromLocalStorage();

    return new HttpHeaders(h);
  }
  getDeviceTokenFromLocalStorage() {
    if (this.localStorageService) {
      let deviceToken = this.localStorageService.get('DeviceToken');
      if (deviceToken) {
        return deviceToken;
      }
      else {
        console.log('deviceToken is null');
        return "null";
      }
    }
    console.log('localStorageService is null');
    return "null";
    
  }
  getSecurityTokenFromLocalStorage() {
    if (this.localStorageService) {
      let token: SecurityTokenModel = this.localStorageService.get('SecurityToken');
      if (token && typeof token === 'object' && token.hasOwnProperty('SecurityToken')) {
        return token.SecurityToken;
      }
    }
    console.log('localStorageService is null');
    return "null";
  }

  getCurrentConfiguration(): ConfigurationModel {
    return this._bsConfiguration.value;
  }

  getCurrentTenant(): TenantModel {
    return this._bsTenant.value;
  }

  getCurrentDeviceToken(): string {
    return this._bsDeviceToken.value;
  }

  getCurrentSecurityToken(): SecurityTokenModel {
    return this._bsSecurityToken.value;
  }

  getCurrentLanguage(): string {
    var local = this.localStorageService.get(LocalStorageNames.Locale);
    if (local != null) {
      return local;
    }
    else if (this.cookieService.check(LocalStorageNames.Locale)) {
      return this.cookieService.get(LocalStorageNames.Locale);
    }
    else {
      return 'en';
    }
  }
  public updateFromAuthenticationReponse(authResponse: AuthenticationBasicResponseModel): void {

    var s: SecurityTokenModel = new SecurityTokenModel
    s.SecurityToken = authResponse.SecurityToken.Value;
    s.ValidUntil = new Date(authResponse.UtcValidUntil);

    this.localStorageService.set(LocalStorageNames.SecurityToken, s);
    this.localStorageService.set(LocalStorageNames.HideNonLeaveMenus, authResponse.HideNonLeaveMenus);
    this.localStorageService.set(LocalStorageNames.EnableGateArrivalsMenu, authResponse.EnableGateArrivalsMenu);

    this._bsSecurityToken.next(s);
  }

  public clearAuthenticationInfo(): void {

    this.localStorageService.remove(LocalStorageNames.SecurityToken);

    this._bsSecurityToken.next(new SecurityTokenModel);
  }

  public loadAllConfigurations(): void {

    this._bsAllConfigsLoaded.next(false);

    this.http.get<ConfigurationModel>(this.configurationUrl)
      .pipe(
        timeout(30000), // 30 sec
        //retry(3),
        retryWhen(RetryStrategy()),
        catchError(this.handleError)
      )
      .subscribe(x => {

        this._bsConfiguration.next(x);

        this.getTenantConfigurations$().subscribe(x => {

          this._bsTenant.next(x);

          this.localStorageService.set(LocalStorageNames.TenantInfo, x);

          var tmpDeviceToken = this.localStorageService.get(LocalStorageNames.DeviceToken);
          if (tmpDeviceToken != null) {
            this._bsDeviceToken.next(tmpDeviceToken);

            var tmpSecurityToken: SecurityTokenModel = this.localStorageService.get(LocalStorageNames.SecurityToken);
            if (tmpSecurityToken != null) {
              this._bsSecurityToken.next(tmpSecurityToken);
            }

            this._bsAllConfigsLoaded.next(true);
          }
          else {
            this.registerApplication$().subscribe(x => {

              this.localStorageService.set(LocalStorageNames.DeviceToken, x.Value);

              this._bsDeviceToken.next(x.Value);

              this._bsAllConfigsLoaded.next(true);
            });
          }
        });
      });
  }
  public getDBConfigurationsValues(keys: string[]): Observable<ConfigurationValueModel[]> {
    var configValuesUrl = this.getApiUrl('ConfigValues');
    return this.http.post<ConfigurationValueModel[]>(configValuesUrl, keys)
      .pipe(
        catchError(this.handleError)
      );
  }
  private getTenantConfigurations$(): Observable<TenantModel> {

    var tenantConfigUrl = this.getApiUrl('TenantConfigurations');

    return this.http.get<TenantModel>(tenantConfigUrl)
      .pipe(
        timeout(30000), // 30 sec
        //retry(3),
        retryWhen(RetryStrategy()),
        catchError(this.handleError)
      );
  }

  private registerApplication$(): Observable<TokenModel> {
    var registerUrl = this.getApiUrl('RegisterApplication');
    var apiHeaders = this.getApiHeaders();

    var body = JSON.stringify({
      "ApplicationVersion": this._bsTenant.value.Version,
      "OperatingSystem": this._deviceInfo.os + (this.ddService.isTablet() ? " [Tablet]" : ""),
      "OperatingSystemVersion": this._deviceInfo.os_version,
      "WebDevice": true,
      "Language": this.getCurrentLanguage()
    });

    return this.http.post<TokenModel>(registerUrl, body, { headers: apiHeaders })
      .pipe(
        timeout(30000), // 30 sec
        //retry(3),
        retryWhen(RetryStrategy()),
        catchError(this.handleError)
      );
  }

  private handleError(error: any) {
    // In a real world app, we might send the error to remote logging infrastructure
    // and reformat for user consumption
    console.error(error); // log to console instead
    return throwError(error);
  }
}
