import { NgModule, ErrorHandler } from '@angular/core';

import { RouterModule } from '@angular/router';
import { CommonModule, registerLocaleData, PathLocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule, MatDialog, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxLocalStorageModule } from "ngx-localstorage";
import { AvatarModule, AvatarSource } from "ngx-avatar";

import { GlobalErrorHandler } from "./_handlers/global-error-handler";
import { HttpErrorInterceptor } from "./_interceptors/http-error.interceptor";

import { AppComponent } from './app.component';
import { AppRoutes } from './app-routing.module';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';

import { InMemProgDataService } from './mocks/in-mem-data.service';

// import ngx-translate  and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
//import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { SizeDetectorComponent } from './core/items/size-detector/size-detector.component';
import { SpinnerComponent } from './core/items/spinner/spinner.component';
//import { SplashComponent } from './core/items/splash/splash.component';
//import { DevFooterComponent } from './core/shared/dev-footer/dev-footer.component';
import { ErrorDialogComponent } from './core/shared/error-dialog/error-dialog.component';

//import { SplashService } from './core/items/splash/service/splash.service';
import { SpinnerService } from './core/items/spinner/service/spinner.service';
import { ResizeService } from './core/items/size-detector/resize.service';

import { ConfigurationService } from './core/services/configuration.service';
import { AuthenticationService } from './core/services/authentication.service';
import { TextService } from './core/services/texts.service';
import { LoggerService } from './core/services/logger.service';
import { InAppService } from './core/services/inapp.service';
import { ThemeSkinService } from './core/services/themeskin.service';
import { SidebarService} from './core/items/sidebar/service/sidebar.service'
import { AgmCoreModule } from '@agm/core';
//import { ErrorDialogService } from "./core/services/error-dialog.service";

import { SenchaLocalStorageService } from './core/shared/sencha/service/sencha-localstorage.service';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
const avatarSourcesOrder = [AvatarSource.CUSTOM, AvatarSource.INITIALS, AvatarSource.VALUE];

import localeSv from '@angular/common/locales/sv';
import localeNb from '@angular/common/locales/nb';
import localeDa from '@angular/common/locales/da';
import localeIs from '@angular/common/locales/is';
import { ScrollingModule, ScrollDispatcher } from '@angular/cdk/scrolling';
import { CustomPaginatorIntl } from './_directive/Pagination/paginationLanguage';
import { MatPaginatorIntl } from '@angular/material/paginator';
registerLocaleData(localeSv);
registerLocaleData(localeNb);
registerLocaleData(localeDa);
registerLocaleData(localeIs);

@NgModule({
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: false
    }),
    HttpClientModule,
    HttpClientInMemoryWebApiModule.forRoot(InMemProgDataService, { delay: 0, passThruUnknownUrl: true }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      countDuplicates: true,
    }),
    AvatarModule,
    CommonModule,
    MatButtonModule,
    MatSnackBarModule,
    MatDialogModule,
    BrowserModule,
    NgxSpinnerModule,
    // ngx-translate and the loader module
    HttpClientModule,
    ScrollingModule,
    NgxLocalStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDrsuPTX4YahWwiGS3lt7tIpsLwkMaTRkY'
    }),
    
  ],
  declarations: [
    AppComponent,
    SizeDetectorComponent,
    SpinnerComponent,
    //SplashComponent,
    //DevFooterComponent,
    ErrorDialogComponent
  ],
  entryComponents: [
    AppComponent,
    ErrorDialogComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    //ErrorDialogService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
    ConfigurationService,
    AuthenticationService,
    TextService,
    ResizeService,
    //SplashService,
    SpinnerService,
    //CookieService,
    SenchaLocalStorageService,
    LoggerService,
    InAppService,
    ThemeSkinService,
    SidebarService
  ],
  exports: [
    TranslateModule,
    NgxSpinnerModule
  ]
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
